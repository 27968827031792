import React, { useEffect } from "react";
import { useGlobalContext } from "../../context/GlobalContext";
import CarImage from "../../assets/img/home/promoColheita.png";

import "./index.scss";
import { ComoParticipar } from "./components/ComoParticipar";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function HomePage() {
  var query = window.location.search.slice(1);
  const globalContext = useGlobalContext();
  useEffect(() => {
    // // Mapear o segundo JSON para um objeto com CPF como chave
    // const secondJsonMap = secondJson.reduce((map, item) => {
    //   map[item.CPF] = item;
    //   return map;
    // }, {});

    // const naoachou = [];

    // // Atualizar o primeiro JSON com os valores de E-MAIL e TELEFONE do segundo JSON
    // const updatedFirstJson = firstJson.map(item => {
    //   const secondJsonItem = secondJsonMap[item.NR_CPF_CNPJ_CLI];
    //   if (secondJsonItem) {
    //     return {
    //       ...item,
    //       "email": secondJsonItem["E-MAIL"],
    //       "telefone": secondJsonItem["TELEFONE"]
    //     };
    //   }
    //   return item;
    // });
    // console.log(updatedFirstJson);
    // distribuir()
    // filtra()
    jsonGerar();
  }, []);

  function jsonGerar() {
    const sequencialArray = [];
    for (let i = 1; i <= 576; i++) {
      sequencialArray.push(i);
    }
    console.log(sequencialArray);
  }

  // function filtra() {
  //   const cpfsCnpjsUnicos = new Set();
  //   const dadosFiltrados = [];

  //   for (const item of oficial) {
  //     const cpfCnpj = item["NR_CPF_CNPJ_CLI"];

  //     if (!cpfsCnpjsUnicos.has(cpfCnpj)) {
  //       cpfsCnpjsUnicos.add(cpfCnpj);
  //       dadosFiltrados.push(item);
  //     }
  //   }

  //   console.log(dadosFiltrados);
  // }

  // function distribuir() {
  //   // Adicione a coluna "Cupons" com números únicos ao JSON
  //   oficial.forEach(entry => {
  //     const qtCupons = parseInt(entry["QT CUPONS"], 10);
  //     const cupons = generateUniqueNumbers(qtCupons);
  //     entry["Cupons"] = cupons.join(", ");
  //   });
  //   console.log(oficial);
  // }

  function generateUniqueNumbers(total) {
    if (total > 99999) {
      console.log("Quantidade de números inválida.");
      return [];
    }

    const uniqueNumbers = new Set();
    const maxPossibleNumber = 99999;

    if (total > maxPossibleNumber) {
      console.log("Quantidade de números inválida.");
      return [];
    }

    while (uniqueNumbers.size < total) {
      const randomNumber = Math.floor(Math.random() * maxPossibleNumber) + 1;
      uniqueNumbers.add("1-" + randomNumber);
    }

    return Array.from(uniqueNumbers);
  }

  return (
    <>
      <div className="main-home">
        <div className="container">
          <div className="left">
            <img src={CarImage} alt="Colheira de Premios BB Seguros" />
          </div>
          <div className="right">
            <div className="content">
              <h2>
                Conheça o Ganhador!
                {/* Participe e concorra a uma <br /> Rampage Laramie 0 km. */}
              </h2>
              <div>
                <p style={{ fontSize: 24 }}>Nome: Emerson F.</p>
                <p style={{ fontSize: 24 }}>Estado: Rio Grande do Sul</p>
              </div>
              {/* <button onClick={() => globalContext.setShowRegister(true)}>
                Cadastre-se
              </button> */}
              {/*  <button
                type="button"
                style={{
                  cursor: "not-allowed",
                }}
              >
                Encerrado
              </button> */}
            </div>
          </div>
        </div>
        <p>
          *Foto meramente ilustrativa - CERTIFICADO DE AUTORIZAÇÃO SRE/ME Nº
          04.032231/2024, consulte o regulamento completo no site:{" "}
          <a href="/">colheitadepremiosbbseguros.com.br</a>
        </p>
      </div>
      <ComoParticipar />
      {/* <Link className="IconWhats">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="28"
          fill="white"
          viewBox="0 0 16 16"
        >
          <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
        </svg>
      </Link> */}
      <footer></footer>
    </>
  );
}
