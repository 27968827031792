import "./ComoParticipar.scss";
import ImageContrato from "../../../assets/img/home/contrato.png";
import ImageCards from "../../../assets/img/home/lequeCards.png";
import ImageSecurity from "../../../assets/img/home/security.png";
import { useGlobalContext } from "../../../context/GlobalContext";

export function ComoParticipar() {
  const globalContext = useGlobalContext();

  return (
    <div className="comoParticipar">
      {/*  <h2>Dia 30/09 será divulgado o vencedor</h2> */}

      <div className="aboutCupons">
        <div className="ganheCupons">
          <div className="card-cupom">
            <div>
              <img src={ImageContrato} alt="Imagem de um Icone de Contrato" />
              <p>
                Efetue a <b>contratação</b> do seguro¹,
                <br /> <b>cadastre-se</b> na plataforma e
              </p>
            </div>
            <p className="blueP">
              <b>
                ganhe <br /> 30 cupons <br /> <span>(números da sorte)</span>
              </b>
            </p>
          </div>
          <div className="card-cupom">
            <div>
              <img
                className="imgCard"
                src={ImageCards}
                alt="Imagem de um Icone de Contrato"
              />
              <p>
                Pague o <b>Seguro com Ourocard Visa</b> na função crédito e
              </p>
            </div>
            <p className="blueP">
              <b>
                ganhe <br /> +30 cupons <br /> <span>(números da sorte)</span>
              </b>
            </p>
          </div>
          <div className="card-cupom">
            <div>
              <img src={ImageSecurity} alt="Imagem de um Icone de Contrato" />
              <p>
                Cliente <b>Superprotegido² BB Seguros cadastre-se e</b>
              </p>
            </div>
            <p className="blueP">
              <b>
                receba <br /> 20 cupons <br /> <span>(números da sorte)</span>
              </b>
            </p>
          </div>
        </div>
        {/*  <a
          href="https://www.bbseguros.com.br/seguros?utm_source=LP-colheita-premios-24&utm_medium=LP&utm_id=colheita-premios-2024"
          target="_blank"
        >
          <b>Clique Aqui.</b> Contrate um novo seguro e aumente as suas chances
          de ganhar !
        </a> */}
      </div>
      <div className="textInfos">
        <h3>
          Quanto mais cupons (números da sorte) você acumular, <br /> mais
          chances tem de ganhar!
        </h3>

        {/* <button onClick={() => globalContext.setShowRegister(true)}>
          Clique agora <br /> e inscreva-se
        </button> */}

        <div>
          <h3>
            Não perca essa chance de ter uma <br /> Picape 0km na sua garagem!
          </h3>
        </div>

        <div className="textRegras">
          <p>
            ¹ Produtos válidos: Seguro Residencial (Planos Essencial, Prático
            Total); Seguro de Vida (Planos Leve, Pleno e Total); Seguro Agrícola
            Flex; Ouro Vida Produtor Rural; Seguro Patrimônio Rural; Seguro
            Pecuário Faturamento e Seguro Vida MPE.
          </p>
          <p>
            ² Cliente que possui 4 ou mais seguros ou planos dos 6 segmentos que
            compõem Seguro de Vida, Residencial, Previdência Privada,
            Capitalização, Seguro Auto e Plano Odontológico.
          </p>
          <p>
            A promoção é válida para contratações no período de 05/02/2024 a
            01/09/2024.
          </p>
          <p>
            Os cupons (números da sorte) serão enviados para o e-mail cadastrado
            após o término do período da campanha.
          </p>
        </div>
      </div>
    </div>
  );
}
