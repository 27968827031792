import React, { useEffect } from "react";
import "./index.scss";
import { Firestore } from "../../utils/firebaseUtils";

const usuariosBrasil2024 = require("./relatorios_jsons/usuarios_brasil2024.json")
const dadosClient = require("./relatorios_jsons/dadosClientes_brasil2024.json")
const dadosCruzados = require("./relatorios_jsons/dadosCruzadosBrasil2024.json")
const dadosFiltrados = require("./relatorios_jsons/dadosFiltradosBrasil2024.json")

export default function DistribuirPontos() {
  // useEffect(() => {
  //   console.log(usuariosBrasil2024);
  // }, []);

  async function cruzarDados() {
    const usuarios = [];
    const userClientes = [];
    const CPFSInvalidos = [];
    dadosClient.map((e) => {
      const valuesVerify = usuariosBrasil2024.find(eFind => apenasNumeros(eFind.CPF) === e.NR_CPF_CNPJ_CLI);

      if (valuesVerify) {
        valuesVerify.total = functionCalcQtdNumbers({ ...e, ...valuesVerify });
        if (!validarCPF(valuesVerify.CPF) && !validarCNPJ(valuesVerify.CPF)) {
          CPFSInvalidos.push(valuesVerify);
        } else {
          userClientes.push({ ...e, ...valuesVerify });
        }
      } else {
        usuarios.push(e);
      }
    })
    console.log("usuários", usuarios);
    console.log("Dados Cruzados", userClientes);
    console.log("CPFS Inválidos", CPFSInvalidos);
  }

  function functionCalcQtdNumbers(element) {
    let totalReturn = 1;

    // VERIFICA SE É CLIENTE BB
    if (element.IN_MCI_CLI === 'Sim') {
      totalReturn = 4;
    }

    // VERIFICA SE É CLIENTE BB SEGUROS
    if (element.IN_CLI_SGDD === "Cliente") {
      totalReturn = 10;
    }

    // VERIFIQUE SE CLIENTE É PROTEGIDO OU NÃO PROTEGIDO
    if (element.IN_CLI_SUP_PTGD === "Sim") {
      totalReturn = 20;
    }

    // VERIFICA SE CONTRATOU SEGURO NO EVENTO
    if (element.IN_CAD_CLI === "Sim") {
      totalReturn = 30;
    }

    // VERIFICA SE PAGOU COM CARTÃO DE CRÉDITO
    if (element.IN_CAD_CLI === "Sim" && element.TX_FMA_PGTO === "Cartao de Credito") {
      totalReturn += 30;
    }

    return totalReturn;
  }

  function apenasNumeros(string) {
    var numsStr = string.replace(/[^0-9]/g, '');
    return parseInt(numsStr);
  }

  function validarCPF(cpf) {
    cpf = cpf.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos

    if (cpf.length !== 11 || /^(.)\1+$/.test(cpf)) {
      return false;
    }

    var soma = 0;
    for (var i = 0; i < 9; i++) {
      soma += parseInt(cpf.charAt(i)) * (10 - i);
    }

    var resto = 11 - (soma % 11);
    if (resto === 10 || resto === 11) {
      resto = 0;
    }

    if (resto !== parseInt(cpf.charAt(9))) {
      return false;
    }

    soma = 0;
    for (var j = 0; j < 10; j++) {
      soma += parseInt(cpf.charAt(j)) * (11 - j);
    }

    resto = 11 - (soma % 11);
    if (resto === 10 || resto === 11) {
      resto = 0;
    }

    if (resto !== parseInt(cpf.charAt(10))) {
      return false;
    }

    return true;
  }

  function validarCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos

    if (cnpj.length !== 14 || /^(.)\1+$/.test(cnpj)) {
      return false;
    }

    // Verifica se os dígitos verificadores são válidos
    var tamanho = cnpj.length - 2;
    var numeros = cnpj.substring(0, tamanho);
    var digitos = cnpj.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;

    for (var i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    var resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(0))) {
      return false;
    }

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (var j = tamanho; j >= 1; j--) {
      soma += parseInt(numeros.charAt(tamanho - j)) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(1))) {
      return false;
    }

    return true;
  }

  function distribuirNumeros() {
    const JsonFinal = [];
    const totalNumeros = 230000; // Precisamos de 230 mil combinações de prefixo e números
    const prefixos = [...Array(10).keys()]; // Prefixos de 0 a 9
    const numerosCombinados = new Set(); // Usaremos um Set para garantir combinações únicas

    // Função auxiliar para gerar um número aleatório de 5 dígitos entre 10000 e 99999
    function gerarNumeroDeCincoDigitos() {
      return Math.floor(Math.random() * 90000) + 10000;
    }

    // Gerar as combinações únicas até atingir o total necessário de 230 mil
    while (numerosCombinados.size < totalNumeros) {
      const prefixoAleatorio = prefixos[Math.floor(Math.random() * 10)]; // Gera um prefixo aleatório de 0 a 9
      const numeroAleatorio = gerarNumeroDeCincoDigitos(); // Gera um número aleatório de 5 dígitos
      const combinacao = `${prefixoAleatorio}-${numeroAleatorio}`; // Cria a combinação "prefixo-numero"
      numerosCombinados.add(combinacao); // Adiciona ao Set para garantir que não haja repetições
    }
    // Convertemos o Set para um array, pois precisamos iterar sobre ele
    let listaCombinacoes = Array.from(numerosCombinados);

    // Loop pelos usuários para adicionar os números aleatórios
    dadosFiltrados.forEach((usuario) => {
      const totalGeral = usuario["total"]; // Quantidade de números que serão adicionados
      const numerosSelecionados = listaCombinacoes.splice(0, totalGeral); // Seleciona os números necessários para esse usuário
      usuario["numeros da sorte"] = numerosSelecionados.join(", "); // Converte o array em uma string separada por vírgulas

      //listaCombinacoes = listaCombinacoes.slice(totalGeral, listaCombinacoes.length)

      JsonFinal.push(usuario);
    });

    console.log(JsonFinal);
  }



  function countNumerosSorte() {
    let count = 0
    dadosFiltrados.forEach(element => {
      count = count + element.total
    });

    console.log(count)
  }

  function filterRepitidos() {
    const mapa = new Map();

    dadosCruzados.forEach(item => {
      // Verifica se o CPF já existe no mapa e se o total atual é maior
      if (!mapa.has(item.NR_CPF_CNPJ_CLI) || mapa.get(item.NR_CPF_CNPJ_CLI).total < item.total) {
        mapa.set(item.NR_CPF_CNPJ_CLI, item);
      }
    });
    console.log(Array.from(mapa.values()))
  }

  function notEnvio() {
    Firestore.collection("errosDistribuiNumber_agrishow_2").get().then((response) => {
      const dataResponse = [];
      response.docs.map(e => {
        const myData = e.data();
        dataResponse.push({ ...myData, values: JSON.parse(myData.data) });
      });
      const filtro = [];
      dataResponse.map((e) => {
        const valuesVerify = dadosCruzados.find(eFind => eFind.cpf === e.values.cpf);
        if (valuesVerify) {
          filtro.push(valuesVerify);
        }
      })
      console.log(filtro);
      console.log(dataResponse);
    })
  }

  return (
    <div>
      {/* <button>Distribuir</button> */}
      <button onClick={cruzarDados}>Cruzar Dados</button>
      <button onClick={countNumerosSorte}>Contar Qtd Numeros da sorte</button>
      <button onClick={filterRepitidos}>Filtrar Dados Cruzados</button>
      <button onClick={distribuirNumeros}>Distribuir Numeros da sorte</button>
      <button onClick={notEnvio}>Get nao enviados</button>
    </div>
  );
}
